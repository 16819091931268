import fetcher from "@src/services/fetcher"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"
import { buildQueryString } from "@src/utils/buildQueryString"

import type { Watchlist } from "./useWatchlist"

export function useWatchlists({
  pagination,
  search,
}: {
  pagination: { pageSize: number; current: number }
  search: string
}): {
  data: { results: Watchlist[]; total_elements: number }
  loading: boolean
  error?: Error
} {
  const { loading, data, error } = useRequest(
    () => {
      const params = buildQueryString({
        page_size: pagination.pageSize,
        page_number: pagination.current,
        name: search,
      })
      return fetcher
        .get(`${BASES_REPUTATIONS_API_URL}/watchlists?${params}`)
        .then((res) => res.data)
    },
    {
      refreshDeps: [pagination.pageSize, pagination.current, search],
    },
  )
  return { loading, data, error }
}
