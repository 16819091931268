import React, { useState } from "react"
import PropTypes from "prop-types"
import { createPortal } from "react-dom"
import { Drawer } from "@elevate_security/elevate-component-library"
import { Table } from "@src/components/MimecastReskin"

import ActivityIndicator from "@src/components/ActivityIndicator"
import { TableWrapper, ErrorMessage } from "./styles"
import { SimpleFilters } from "@src/components/SimpleFilters"
import { SEARCH_PLACEHOLDERS, LOGS_PAGE_EXPORT_TYPE } from "@src/constants"
import ExportData from "@src/components/ExportData"
import DragosaurWithUmbrella from "@src/components/DragosaurWithUmbrella"
import { PageLayout } from "@src/components/PageLayout"
import { SearchInputWithFilters } from "@src/components/SearchInputWithFilters"
import { TableCard } from "@src/components/TableCard"
import { SpacingY } from "@src/components/SpacingY"

const MemoTable = React.memo(Table)

export const TableWithFiltersScreen = ({
  title,
  appliedFiltersCount,
  searchValue,
  onSearch,
  clearSearch,
  children,
  entries,
  totalCount,
  loading,
  pagination,
  columns,
  onSortedChange,
  onChangeTable,
  excludeSortColumn = [],
  excludeFromClickRow = [],
  showExportData = false,
  filters = "",
  orderBy = "created_datetime desc",
  showSearch = true,
  subtitle = "",
  placeholder,
  emptyMessage,
  errorMessage,
}) => {
  const [showFilters, setShowFilters] = useState(false)

  const toggleFilters = (val) => {
    setShowFilters((prev) => val ?? !prev)
  }

  const current =
    pagination?.current || pagination.offset / pagination.limit + 1

  const handleChangeTable = async (info) => {
    if (loading) {
      return
    }

    const page = info.pagination.offset / info.pagination.limit + 1
    if (
      pagination.current !== page ||
      pagination.pageSize !== info.pagination.limit
    ) {
      pagination.onChange(page, info.pagination.limit)
    }
  }

  return (
    <PageLayout
      title={title}
      subtitle={subtitle}
      actions={
        showExportData && (
          <ExportData
            total={pagination?.total}
            type={LOGS_PAGE_EXPORT_TYPE[title] || "default"}
            filters={filters}
            riskAnalysisFlag={true}
            order_by={orderBy}
          />
        )
      }
    >
      <>
        <SpacingY>
          {showSearch && (
            <SearchInputWithFilters
              value={searchValue}
              filtersCount={appliedFiltersCount}
              handleSearch={onSearch}
              isClearable={true}
              clearSearch={clearSearch}
              placeholder={
                placeholder || SEARCH_PLACEHOLDERS[title] || "Search here"
              }
              onToggleFilters={() => {
                toggleFilters()
              }}
              showFilterToggle={Boolean(children)}
            />
          )}
          {loading && !errorMessage ? (
            <ActivityIndicator active={true} />
          ) : (
            <TableCard>
              <TableWrapper>
                {errorMessage ? (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                ) : totalCount === 0 ? (
                  <DragosaurWithUmbrella
                    message={
                      emptyMessage ||
                      `No ${title.toLowerCase()} available to show`
                    }
                    showTooltip={false}
                  />
                ) : (
                  <MemoTable
                    manual
                    totalCount={totalCount || 0}
                    data={entries}
                    defaultPage={current}
                    defaultPageSize={pagination.pageSize || pagination.limit}
                    columns={columns}
                    onChangeTable={onChangeTable ?? handleChangeTable}
                    onSortedChange={onSortedChange}
                    excludeSortColumn={excludeSortColumn}
                    excludeFromClickRow={excludeFromClickRow}
                  />
                )}
              </TableWrapper>
            </TableCard>
          )}
        </SpacingY>
        {children &&
          createPortal(
            <Drawer
              isOpen={showFilters}
              direction="right"
              width="480px"
              toggleDrawer={toggleFilters}
            >
              <SimpleFilters onClose={() => toggleFilters(false)}>
                {children}
              </SimpleFilters>
            </Drawer>,
            document.body,
          )}
      </>
    </PageLayout>
  )
}

TableWithFiltersScreen.propTypes = {
  onChangeTable: PropTypes.oneOf([PropTypes.function, PropTypes.null]),
}

TableWithFiltersScreen.defaultProps = {
  onChangeTable: null,
}
