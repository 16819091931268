/* eslint-disable react/prop-types */
import HelpTooltip from "@src/components/HelpTooltip"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useIndividualProfileState } from "../../../IndividualProfileContextProvider"
import { AttackInfoContainer } from "./styles"
import { TOOLTIP_DESCRIPTION } from "./utils"
import { useSelector } from "react-redux"
import { getWhoAMI } from "@src/services/selectors/visionSelectors"
import { darklyGetFlag } from "@src/utils/darkly"
import { viewType } from "@src/scenes/ManagerDashboard/scenes/Session/ManagerDashboardV2/utils"
import { truncateToOneDecimalPlace } from "@src/utils/numbers/utils"
import HumanRiskScoreGauge from "@src/components/HumanRiskScoreGauge/HumanRiskScoreGauge"
import { GaugeGraphsContainer } from "@src/components/HumanRiskScoreGauge/styles"
import { HumanRiskBehaviorsSection } from "@src/scenes/HumanRiskDashboard/components/HumanRiskBehaviors/HumanRiskBehaviors"
import { DashboardCard } from "@src/components/DashboardCard"
import { SpacingY } from "@src/components/SpacingY"
import { SpacingX } from "@src/components/SpacingX"
import { IRPSummaryStatisticsSection } from "@src/scenes/IndividualProfilePage/IRPSummaryStatistics"
import { HumanRiskScoreTrendGraphIRP } from "../TrendAttackFactor/TrendingOverTimeIRP"
import TrendAttackGraph from "../TrendAttackFactor/TrendAttackGraph"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { ComboBoxWithOverlap } from "@src/components/ComboBoxWithOverlap"
import { getGlobalConfig } from "@src/globalConfig"

export const AttackInfo = ({ value, description }) => (
  <AttackInfoContainer>
    <h1>{value}</h1>
    <span>
      {description.toUpperCase()}
      <HelpTooltip body={TOOLTIP_DESCRIPTION[description]} placement="bottom" />
    </span>
  </AttackInfoContainer>
)

AttackInfo.propTypes = {
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

function IRPGraphSelect({ onChange, value }) {
  const graphOptions = [
    { label: "Show Human Risk Score only", value: "hrs_only" },
    { label: "Show all behavior trends", value: "all_behaviors" },
  ]
  const chosenOption = graphOptions.find((option) => option.value === value)
  return (
    <ComboBoxWithOverlap
      value={chosenOption}
      data={graphOptions}
      onChange={(selected) => {
        onChange(selected.value)
      }}
      defaultValue={chosenOption}
    />
  )
}

export default function IndividualHumanRiskInfoSection({
  actionData,
  onSwitchToEventsTab,
  onSwitchToActionlogsTab,
}) {
  const { individualData } = useIndividualProfileState()
  const person_nid = individualData.es_person_id
  const showMDV2 = darklyGetFlag("manager-dashboard-v2")
  const userData = useSelector(getWhoAMI)
  const hideItem = (showMDV2 ? viewType(userData) : "") === "manager"
  const timePeriodValue =
    useSelector((state) => state.get("reputationsReducer"))
      ?.time_period_value || ""
  const activeActions = useSelector(selectActiveActions)

  const [chosenGraph, setChosenGraph] = useState("hrs_only")

  if (!individualData) return null

  const score = truncateToOneDecimalPlace(
    hideItem
      ? individualData?.actions?.overall
      : individualData?.risks?.overall,
  )

  const gaugeFooterText = "Your risk rating is"

  return (
    <SpacingY size="md">
      <GaugeGraphsContainer>
        <HumanRiskScoreGauge
          score={score}
          footerText={gaugeFooterText}
          isFooterInline={true}
          containerWidth={300}
        />
        <DashboardCard
          title="Score Trends Over Time"
          variant="transparent"
          renderControls={() => {
            return getGlobalConfig("ENABLE_IRP_ALL_TRENDS_GRAPH") ? (
              <div style={{ width: "270px" }}>
                <IRPGraphSelect
                  onChange={(value) => {
                    setChosenGraph(value)
                  }}
                  value={chosenGraph}
                />
              </div>
            ) : null
          }}
        >
          {chosenGraph === "hrs_only" && <HumanRiskScoreTrendGraphIRP />}
          {chosenGraph === "all_behaviors" && (
            <TrendAttackGraph
              hideItem={hideItem}
              timePeriodValue={timePeriodValue}
              activeActions={activeActions}
              showDropDown={false}
            />
          )}
        </DashboardCard>
      </GaugeGraphsContainer>
      <SpacingX wrap={false} size="md">
        <DashboardCard
          flex="3"
          title={getGlobalConfig("BEHAVIOR_BREAKDOWN_TITLE")}
          subtitle="Measures how frequently you engage in risky behaviors online"
        >
          <HumanRiskBehaviorsSection
            actionFactorScoreData={actionData}
            person_nid={person_nid}
            timePeriodValue={timePeriodValue}
            onClickOverride={onSwitchToEventsTab}
          />
        </DashboardCard>
        <DashboardCard flex="2" title="Risk Responses">
          <IRPSummaryStatisticsSection
            onSwitchToEventsTab={onSwitchToEventsTab}
            onSwitchToActionlogsTab={onSwitchToActionlogsTab}
            person_nid={person_nid}
            timePeriodValue={timePeriodValue}
          />
        </DashboardCard>
      </SpacingX>
    </SpacingY>
  )
}
