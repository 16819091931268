import React from "react"
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom"

import ReportsDashboards from "@src/scenes/ReportsDashboards/ReportsDashboards"
import Dashboard from "@src/scenes/Dashboard"
import HumanRiskDashboard from "@src/scenes/HumanRiskDashboard"
import IndividualRiskAnalysis from "@src/scenes/IndividualRiskAnalysis"
import IndividualRiskAnalysisContextProvider from "./SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import { IndividualProfilePage } from "./IndividualProfilePage"
import ManagerDashboard from "@src/scenes/ManagerDashboard"
import RiskDetectionRulesScreen from "@src/scenes/RiskDetectionRulesScreen"
import ActionableInsightsDetails from "./Dashboard/components/FirstQuestion/components/ActionableInsights/ActionableInsightDetails"
import { RestrictedRoute } from "@src/components/RestrictedRoute"

import { darklyGetFlag } from "@src/utils/darkly"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import { RiskResponseRuleCreateScreen } from "./RiskResponseRuleCreateScreen"
import { DetectionsScreen } from "./DetectionsScreen"
import { ApplicationsScreen } from "./ApplicationsScreen"
import { ActionLogsScreen } from "./ActionLogsScreen"
import { AuditLogsScreen } from "./AuditLogsScreen"
import {
  WatchlistsScreen,
  WatchlistIndividualsScreen,
} from "./WatchlistsScreen"

type Props = RouteComponentProps & {
  resetError: () => void
}

const VisionContainer = (props: Props) => {
  const { history, location } = props

  history.listen(() => {
    props.resetError()
  })

  const showActionableInsights = darklyGetFlag("dashboard-actionable-insights")
  const hideRiskDetectionRules = getOfferingPlan() === "assess"

  return (
    <>
      <Switch>
        <Route
          exact
          path={[
            "/vision2/:profileId/profile",
            "/engagement/vision2/:profileId/profile",
          ]}
          component={IndividualProfilePage}
        />
        <Route
          exact
          path="/engagement/vision2/dashboard"
          render={() => <HumanRiskDashboard />}
        />
        <Route
          exact
          path="/engagement/vision2/insights"
          render={() => <Dashboard history={props.history} />}
        />
        <RestrictedRoute
          exact
          path="/engagement/vision2/insights/individualInsightInfo/:entity"
          render={() => <ActionableInsightsDetails />}
          shouldRender={showActionableInsights}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <Route
          exact
          path="/engagement/vision2/insights/:riskType/:actionType"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <IndividualRiskAnalysis history={props.history} />
            </IndividualRiskAnalysisContextProvider>
          )}
        />
        <Route
          exact
          path="/engagement/vision2/insights/detections"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <DetectionsScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
        />
        <Route
          path="/engagement/vision2/manager-dashboard/team-trends/:person_nid?"
          render={() => (
            <ManagerDashboard history={history} location={location} />
          )}
        />
        <Route
          path="/engagement/vision2/manager-dashboard/:person_nid?"
          render={() => (
            <ManagerDashboard history={history} location={location} />
          )}
        />
        <Route
          path="/engagement/vision2/dashboards/keyinsights"
          render={() => <ReportsDashboards dashboardId="key-insights" />}
        />
        <Route
          path="/engagement/vision2/dashboards/repeatperformers"
          render={() => (
            <ReportsDashboards dashboardId="top-bottom-repeat-performers" />
          )}
        />
        <Route
          path="/engagement/vision2/dashboards/riskiestgroups"
          render={() => <ReportsDashboards dashboardId="riskiest-groups" />}
        />
        <Route
          path="/engagement/vision2/dashboards/phishingreports"
          render={() => (
            <ReportsDashboards dashboardId="phishing-reporting-dashboard" />
          )}
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules/create-rule"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <RiskResponseRuleCreateScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules/edit-rule/:policyId"
          render={() => (
            <IndividualRiskAnalysisContextProvider>
              <RiskResponseRuleCreateScreen />
            </IndividualRiskAnalysisContextProvider>
          )}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/risk-response-rules"
          render={() => <RiskDetectionRulesScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/action-logs"
          render={() => <ActionLogsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/audit-logs"
          render={() => <AuditLogsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/applications"
          render={() => <ApplicationsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/risk-response-engine/risk-response-rules"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/watchlists/:watchlistId/individuals"
          render={() => <WatchlistIndividualsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
        <RestrictedRoute
          path="/engagement/vision2/risk-response-engine/watchlists"
          render={() => <WatchlistsScreen />}
          shouldRender={!hideRiskDetectionRules}
          fallbackPath="/engagement/vision2/dashboard"
        />
      </Switch>
    </>
  )
}

export default withRouter(VisionContainer)
