import React from "react"
import moment from "moment"

import { Separator, FilterTitle } from "@src/components/SimpleFilters/styles"
import MultiSelectFilter from "../IndividualProfilePage/components/MoreFilter/MultiSelectFilter"
import { humanize } from "@src/utils/string"
import DateRange from "../IndividualProfilePage/components/MoreFilter/DateRange"
import type { AppliedFiltersType } from "./useMimecastActionLogs"
import { MoreFilterInfoMessage } from "../IndividualProfilePage/components/MoreFilter/MoreFilterInfoMessage"

const FILTERS = [
  {
    key: "action_status_filter",
    label: "Status",
    options: ["success", "failed", "suppressed"],
  },
  {
    key: "action_filter",
    label: "Action",
    options: ["email", "email_with_video"],
  },
] as const

type Props = {
  appliedFilters: AppliedFiltersType
  setAppliedFilters: (
    updater: (prev: AppliedFiltersType) => AppliedFiltersType,
  ) => void
}

export function MimecastActionLogsFilters({
  appliedFilters,
  setAppliedFilters,
}: Props) {
  return (
    <>
      {FILTERS.map(({ key, options, label }) => {
        return (
          <React.Fragment key={key}>
            <MultiSelectFilter
              showAllFilters
              section={{
                key,
                title: <FilterTitle>{label}</FilterTitle>,
                items: options.map((option) => ({
                  label: humanize(option),
                  value: option,
                })),
              }}
              filters={
                appliedFilters[key]?.map((value: string) => ({
                  key,
                  value,
                })) ?? []
              }
              handleChange={(item: { value: string; checked: boolean }) => {
                setAppliedFilters((prev) => ({
                  ...prev,
                  [key]: item.checked
                    ? (prev[key] ?? []).concat(item.value)
                    : (prev[key] ?? []).filter((value) => value !== item.value),
                }))
              }}
            />
            <Separator />
          </React.Fragment>
        )
      })}
      <DateRange
        section={{
          title: <FilterTitle>Execution Date</FilterTitle>,
          type: "date",
          key: "execution_datetime",
        }}
        value={[
          appliedFilters.executed_datetime_start,
          appliedFilters.executed_datetime_end,
        ]}
        onChange={({ value: [start, end] }: { value: [string, string] }) => {
          setAppliedFilters((prev) => ({
            ...prev,
            executed_datetime_start: start,
            executed_datetime_end: end,
          }))
        }}
      />
      <MoreFilterInfoMessage
        message={`Current timezone: UTC${moment().format("Z")}`}
      />
    </>
  )
}
