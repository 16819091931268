import React, { useEffect, useMemo } from "react"
import {
  TableLinkColWrapper,
  TableHeaderWrapper,
  LatestEventHeader,
  LatestEventsLink,
  TableBodyWrapper,
  TableColWrapper,
  TableWrapper,
  TableBody,
  Wrapper,
} from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/LatestEvents/styles"
import { humanize } from "@src/utils/string"
import TextOverflow from "@src/scenes/Dashboard/components/FirstQuestion/components/HumanRiskIndividual/TextOverflow"
import { formatDate } from "@src/utils/dates"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { getActionName } from "@src/components/ActionChart/utils"
import { getLatestEventsLog } from "@src/services/apis/reputation"
import useRequest from "@ahooksjs/use-request"
import { LatestEventsTableSkeleton } from "./LatestEventsTableSkeleton"
import { useSelector } from "react-redux"
import { THEME_PRIMARY } from "@src/theme"

const dummyLatestEvents = [
  {
    es_person_id: "Sul448E5hUS4gPFFDnwj3OV88wqvm2",
    full_name: "Elizet Pruiett",
    event: "Actual phishing email clicked",
    date: "2021-09-21T00:00:00",
  },
  {
    es_person_id: "kS6KZyPURKp0LFO2HlICfunT0DtxsJ",
    full_name: "Shree Rezendes",
    event: "Actual phishing email clicked",
    date: "2021-08-21T00:00:00",
  },
  {
    es_person_id: "9GuGT7oalLSIIT3j7dmzks0Gsm9jeF",
    full_name: "Laresa Hustace",
    event: "Actual phishing email clicked",
    date: "2021-08-21T00:00:00",
  },
  {
    es_person_id: "NxFosD6kpHk0ydREEyqsv5RbxpuTJM",
    full_name: "Daphine Anchors",
    event: "Actual phishing email clicked",
    date: "2021-08-11T00:00:00",
  },
  {
    es_person_id: "GJ4WEMBAcZocoLWCDtAV0GXGNckQLv",
    full_name: "Lydell Negbenebor",
    event: "Actual phishing email clicked",
    date: "2021-08-07T00:00:00",
  },
]
function LatestEvents({ keyFactor, widthInPercentage, openDetail, setData }) {
  const history = useHistory()
  const { latest_events_loader, latest_events_data } = useSelector((state) =>
    state.get("reputationsReducer"),
  )
  const { run } = useRequest(
    () =>
      getLatestEventsLog(keyFactor?.replaceAll("-", "_"), {
        page_size: 5,
      }),
    {
      paginated: false,
      defaultPageSize: 5,
      manual: true,
      formatResult: (response) => {
        const data = {
          list: response?.data,
        }
        setData(data)
        return data
      },
    },
  )

  const latestEvents = useMemo(() => {
    //When it comes to the optional chaining operator, anything with length, cannot be handled alone with the operator.  It will throw
    //an exception about length not being a property of undefined.  So you need to check that it exists first
    return !latest_events_loader &&
      latest_events_data &&
      latest_events_data?.list &&
      latest_events_data?.list?.length > 0
      ? latest_events_data.list
      : dummyLatestEvents
  }, [latest_events_data?.list])

  const handler = (profileId) => {
    history.push(`/engagement/vision2/${profileId}/profile`)
  }
  useEffect(() => {
    if (latest_events_loader) run()
  }, [])

  return (
    <Wrapper widthInPercentage={`${widthInPercentage}%`}>
      <LatestEventHeader>
        Latest Events - {getActionName(keyFactor)}
      </LatestEventHeader>
      <TableWrapper>
        <TableHeaderWrapper width="25%">Individual</TableHeaderWrapper>
        <TableHeaderWrapper width="50%">Event</TableHeaderWrapper>
        <TableHeaderWrapper width="25%">Date</TableHeaderWrapper>
      </TableWrapper>
      {!latest_events_loader ? (
        <TableBodyWrapper>
          {latestEvents.map((latestData, index) => {
            return (
              <TableBody key={`${index}-${latestData.es_person_id}`}>
                <TableLinkColWrapper
                  width="25%"
                  onClick={() => handler(latestData.es_person_id)}
                >
                  <TextOverflow
                    name={humanize(latestData.full_name || "")}
                    color={THEME_PRIMARY}
                  />
                </TableLinkColWrapper>
                <TableColWrapper width="50%">
                  <TextOverflow
                    name={humanize(latestData.event || "")}
                    color="#34383C"
                  />
                </TableColWrapper>
                <TableColWrapper width="25%">
                  {formatDate({
                    date: latestData.date,
                    format: "MMM DD, YYYY",
                  })}
                </TableColWrapper>
              </TableBody>
            )
          })}
        </TableBodyWrapper>
      ) : (
        <LatestEventsTableSkeleton />
      )}
      {!latest_events_loader && (
        <LatestEventsLink onClick={openDetail}>
          See {getActionName(keyFactor)} events log
        </LatestEventsLink>
      )}
    </Wrapper>
  )
}

export default LatestEvents

LatestEvents.defaultProps = {
  keyFactor: "",
  widthInPercentage: 50,
  openDetail: undefined,
  setData: () => null,
}
LatestEvents.propTypes = {
  keyFactor: PropTypes.string,
  widthInPercentage: PropTypes.number,
  openDetail: PropTypes.func,
  setData: PropTypes.func,
}
