import React, { useState } from "react"

import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { useDebounceFn } from "ahooks"
import { Link } from "react-router-dom"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { useWatchlists as _useWatchlists } from "./useWatchlists"
import { TableCellSpan } from "@src/components/TableCellSpan"

export function WatchlistsScreen({ useWatchlists = _useWatchlists }) {
  const [pagination, setPagination] = useState({ pageSize: 25, current: 1 })
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const { run: setSearchDebounced } = useDebounceFn(setSearch, { wait: 250 })

  const { data, loading, error } = useWatchlists({ search, pagination })

  const columns = [
    {
      key: "name",
      header: "NAME",
      render: (name: string, { watchlist_nid }: { watchlist_nid: string }) => {
        return (
          <TableCellSpan allowCellWrap={true}>
            <Link
              to={`/engagement/vision2/risk-response-engine/watchlists/${watchlist_nid}/individuals`}
            >
              {name}
            </Link>
          </TableCellSpan>
        )
      },
    },
    {
      key: "description",
      header: "DESCRIPTION",
      render: (description: string) => {
        return <TableCellSpan allowCellWrap={true}>{description}</TableCellSpan>
      },
    },
  ]

  return (
    <div>
      {loading && <ActivityIndicator active />}
      <MimecastCgBreadcrumbs
        breadcrumbs={["risk_response_engine", "watchlists"]}
      />
      <TableWithFiltersScreen
        title="Watchlists"
        appliedFiltersCount={0}
        searchValue={searchText}
        onSearch={(val: string) => {
          setSearchDebounced(val)
          setSearchText(val)
        }}
        clearSearch={() => {
          setSearch("")
          setSearchText("")
        }}
        entries={data?.results ?? []}
        totalCount={data?.total_elements ?? 0}
        loading={!data}
        pagination={{
          ...pagination,
          onChange: (current: number, pageSize: number) => {
            setPagination({ current, pageSize })
          },
        }}
        columns={columns}
        onSortedChange={() => {}}
        excludeSortColumn={columns.map((column) => column.key)}
        errorMessage={error && `Failed to load watchlists ${error.message}`}
      >
        {null}
      </TableWithFiltersScreen>
    </div>
  )
}
