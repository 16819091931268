import React, { useEffect } from "react"

import Vision, { VisionProps } from "@src/scenes"
import store from "@src/services/redux/store"
import { Provider, useDispatch } from "react-redux"
import { setActiveActions } from "./services/redux/reputations/actions"
import { getGlobalConfig } from "./globalConfig"

type VisionRootProps = {
  logindata: VisionProps["logindata"]
  companyInfoOverride?: VisionProps["companyInfo"]
  observeRouteChanges?: VisionProps["observeRouteChanges"]
  awsRum?: VisionProps["awsRum"]
}

const ACTIVE_ACTIONS_OVERRIDE = getGlobalConfig("ACTIVE_ACTIONS_OVERRIDE")

export const VisionRoot = ({
  logindata,
  companyInfoOverride,
  observeRouteChanges = false,
  awsRum = null,
}: VisionRootProps) => {
  return (
    <Provider store={store}>
      <SetActiveActions activeActions={ACTIVE_ACTIONS_OVERRIDE} />
      <Vision
        logindata={logindata}
        activeActionsOverride={ACTIVE_ACTIONS_OVERRIDE}
        companyInfoOverride={companyInfoOverride}
        observeRouteChanges={observeRouteChanges}
        awsRum={awsRum}
      />
    </Provider>
  )
}

// Using a null component here so we can be inside the Provider context
// This code should maybe go in the Vision component directly
function SetActiveActions({
  activeActions,
}: {
  activeActions: string[] | null
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeActions) {
      dispatch(setActiveActions(activeActions))
    }
  }, [activeActions, dispatch])

  return null
}
