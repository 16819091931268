import React from "react"
import PropTypes from "prop-types"
import {
  Gauge,
  Badge,
  Typography,
} from "@elevate_security/elevate-component-library"
import { truncateToOneDecimalPlace } from "@src/utils/numbers/utils"
import { GaugeFooter, GaugeWrapper } from "./styles"
import { DashboardCard } from "../DashboardCard"
import { HumanRiskDashboardHumanRiskScoreInfoTooltip } from "../DashboardInfoTooltips"

function HumanRiskScoreGauge({
  score,
  footerText,
  isFooterInline,
  containerWidth,
}) {
  const { Text } = Typography

  const getScoreLabel = () => {
    let label = ""

    if (score < 2) {
      label = "Very Low"
    } else if (score < 4) {
      label = "Low"
    } else if (score < 6) {
      label = "Medium"
    } else if (score < 8) {
      label = "High"
    } else {
      label = "Very High"
    }

    return label
  }

  const getTheme = () => {
    return getScoreLabel().toLowerCase().replace(" ", "")
  }

  return (
    <DashboardCard
      title="Human Risk Score"
      subtitle="Based on user behavior and actions"
      info={<HumanRiskDashboardHumanRiskScoreInfoTooltip />}
      style={{ maxWidth: "450px" }}
      imageExportId="HumanRiskScoreGauge"
    >
      <GaugeWrapper>
        <Gauge
          score={score}
          containerWidth={containerWidth}
          formatter={(val) => {
            // This ensures we show "0" for 0 and "10" for 10. The endpoints are
            // a special case where we don't want to show decimal places due to
            // weird spacing with the score indicator.
            return val > 0 && val < 10 ? truncateToOneDecimalPlace(val) : val
          }}
        />
      </GaugeWrapper>
      <GaugeFooter>
        <Text
          style={{
            fontSize: 18,
            fontWeight: 600,
            marginBottom: 15,
            marginRight: isFooterInline ? 10 : 0,
            display: isFooterInline ? "inline" : "block",
          }}
        >
          {footerText}
        </Text>
        <Badge
          text={getScoreLabel()}
          theme={getTheme()}
          shape="largeboldround"
          fontSize="18px"
        />
      </GaugeFooter>
    </DashboardCard>
  )
}

export default HumanRiskScoreGauge

HumanRiskScoreGauge.propTypes = {
  score: PropTypes.number,
  footerText: PropTypes.string,
  isFooterInline: PropTypes.bool,
  containerWidth: PropTypes.number,
}

HumanRiskScoreGauge.defaultProps = {
  score: 0,
  footerText: "",
  isFooterInline: false,
  containerWidth: 270,
}
