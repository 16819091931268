import React from "react"

import {
  Typography,
  Icon as IconComponenet,
} from "@elevate_security/elevate-component-library"
import { SearchInput } from "@src/components/MimecastReskin"

import {
  SearchInputWithFiltersWrapper,
  ToggleFilterButton,
  AppliedFiltersCount,
  CrossButton,
  ClearSearchButton,
} from "./SearchInputWithFilters.styles"
import Icon from "../Icon"
import { isEmpty } from "@src/utils/string"
import { SpaceBetween } from "../SpaceBetween"

export interface SearchInputWithFiltersProp {
  readonly value: string
  readonly placeholder: string
  readonly filtersCount: number
  readonly isClearable: boolean
  readonly handleSearch: (value: string) => void
  readonly clearSearch: () => void
  readonly clearFilters: () => void
  readonly onToggleFilters: () => void
  readonly showFilterToggle?: boolean
}

export function SearchInputWithFilters(props: SearchInputWithFiltersProp) {
  const {
    value,
    filtersCount,
    handleSearch,
    isClearable,
    clearSearch,
    clearFilters,
    placeholder,
    onToggleFilters,
    showFilterToggle = true,
  } = props

  const showClearSearchIcon = !isEmpty(value) && isClearable
  const filtersCountText = `${filtersCount} active filter${
    filtersCount > 1 ? "s" : ""
  }`

  const generateRightActions = () => {
    return (
      <SpaceBetween direction="row" alignItems="center" gap="16px">
        {showClearSearchIcon && (
          <ClearSearchButton
            onClick={clearSearch}
            data-test-id="clear-search-button"
          >
            <IconComponenet name="CircleXSolid" size="lg" />
          </ClearSearchButton>
        )}
        {filtersCount > 0 && (
          <AppliedFiltersCount direction="row" alignItems="center" gap="8px">
            <Typography.Text
              fontWeight="bold"
              color="500"
              data-test-id="active-filters-count"
            >
              {filtersCountText}
            </Typography.Text>
            {clearFilters && (
              <CrossButton
                onClick={clearFilters}
                data-test-id="clear-filters-button"
              >
                <IconComponenet name="Close" size="xsm" />
              </CrossButton>
            )}
          </AppliedFiltersCount>
        )}
        {showFilterToggle && (
          <ToggleFilterButton
            onClick={onToggleFilters}
            data-test-id="toggle-filters-button"
          >
            <Icon name="sliders" />{" "}
          </ToggleFilterButton>
        )}
      </SpaceBetween>
    )
  }

  return (
    <SearchInputWithFiltersWrapper
      direction="row"
      justify="flex-start"
      data-test-id="search-input-with-wrapper"
    >
      <SearchInput
        value={value}
        handleSearch={handleSearch}
        isClearable={false}
        placeholder={placeholder}
        inputProps={{
          width: "full",
          iconRight: generateRightActions(),
        }}
      />
    </SearchInputWithFiltersWrapper>
  )
}
