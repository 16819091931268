import React from "react"
import { CustomSelect } from "@src/components/MimecastReskin"
import { TIME_PERIOD_LIST } from "@src/scenes/HumanRiskDashboard/constants"
import { Label, TimePeriodSelectWrapper } from "./styles"
import PropTypes from "prop-types"

function TimePeriod({ initialValue, timePeriodHandler }) {
  return (
    <TimePeriodSelectWrapper>
      <Label>Timeframe:</Label>
      <CustomSelect
        options={TIME_PERIOD_LIST}
        defaultValue={initialValue}
        onChange={timePeriodHandler}
      />
    </TimePeriodSelectWrapper>
  )
}

export default TimePeriod

TimePeriod.propTypes = {
  initialValue: PropTypes.string,
  padding: PropTypes.string,
  timePeriodHandler: PropTypes.func,
}
