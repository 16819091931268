import React from "react"
import PropTypes from "prop-types"
import {
  SummaryStatisticsValue,
  SummaryStatisticsItem,
  RiskResponseValue,
  RiskResponseFooter,
  SummaryStatisticsTitleItem,
  SummaryStatisticsBox,
  Divider,
  IRPSummaryStatisticsBox,
  IRPDivider,
  SummaryStatisticsFooterLink,
  SummaryStatisticsFooterButton,
} from "./styles"
import { truncateNumber } from "@src/utils/numbers/utils"
import { humanize } from "@src/utils/string"

function ShowTileData({ tileData }) {
  const gridItems = tileData.map((data) => (
    <SummaryStatisticsItem key={data.name}>
      <SummaryStatisticsValue>
        {truncateNumber(data.events)}
      </SummaryStatisticsValue>{" "}
      {data.name === "training" ? (
        <SummaryStatisticsFooterButton onClick={data.onClick}>
          {humanize(data.name)}
        </SummaryStatisticsFooterButton>
      ) : (
        <SummaryStatisticsFooterLink to={data.onClick}>
          {humanize(data.name)}
        </SummaryStatisticsFooterLink>
      )}
    </SummaryStatisticsItem>
  ))

  return gridItems
}

function IRPShowTileData({ tileData }) {
  const updatedLabel = {
    notifications: "Notifications delivered",
    outbound_actions: "Outbound actions taken",
    training: "Training assigned",
  }
  const gridItems = tileData.map((data) => (
    <SummaryStatisticsItem key={data.name}>
      <SummaryStatisticsValue>
        {truncateNumber(data.events)}
      </SummaryStatisticsValue>{" "}
      <SummaryStatisticsFooterButton onClick={data.onClick}>
        {humanize(updatedLabel[data.name])}
      </SummaryStatisticsFooterButton>
    </SummaryStatisticsItem>
  ))

  return gridItems
}

function ShowRiskResponseData({ riskResponseData }) {
  const gridItems = riskResponseData.map((tile) => (
    <SummaryStatisticsTitleItem key={tile.name}>
      <RiskResponseValue>{truncateNumber(tile.events)}</RiskResponseValue>
      <RiskResponseFooter>Risk Responses</RiskResponseFooter>
    </SummaryStatisticsTitleItem>
  ))

  return gridItems
}

export default function SummaryComponent({
  riskResponseData,
  tileData,
  direction,
}) {
  return (
    <>
      {direction === "horizontal" ? (
        <SummaryStatisticsBox>
          <ShowRiskResponseData riskResponseData={riskResponseData} />
          <Divider />
          <ShowTileData tileData={tileData} />
        </SummaryStatisticsBox>
      ) : (
        <IRPSummaryStatisticsBox>
          <ShowRiskResponseData riskResponseData={riskResponseData} />
          <IRPDivider />
          <IRPShowTileData tileData={tileData} />
        </IRPSummaryStatisticsBox>
      )}
    </>
  )
}

ShowTileData.propTypes = {
  dataList: PropTypes.array.isRequired,
}
