import fetcher from "@src/services/fetcher"
import { useRequest } from "ahooks"
import { BASES_REPUTATIONS_API_URL } from "@src/constants"

export type Watchlist = {
  name: string
  description: string
  watchlist_nid: string
}

export function useWatchlist({ watchlistId }: { watchlistId: string }): {
  watchlist: Watchlist
  loading: boolean
  error?: Error
} {
  const {
    data: watchlist,
    loading,
    error,
  } = useRequest(() =>
    fetcher
      .get(`${BASES_REPUTATIONS_API_URL}/watchlists/${watchlistId}`)
      .then((res) => res.data),
  )

  return {
    watchlist,
    loading,
    error,
  }
}
