import React from "react"
import PropTypes from "prop-types"
import {
  HumanRiskBehaviorsItem,
  HumanRiskBehaviorsLeftBar,
  HumanRiskBehaviorsItemTitleRight,
  HumanRiskBehaviorsItemTitleLeft,
  HumanRiskBehaviorsItemBody,
  HumanRiskBehaviorsBox,
  HumanRiskBehaviorsItems,
  HumanRiskBehaviorsItemTitleText,
  HumanRiskBehaviorsItemTitle,
  HumanRiskBehaviorsItemDetails,
  HumanRiskBehaviorsItemDetailsLeft,
  HumanRiskBehaviorsItemDetailsRight,
  HumanRiskBehaviorsItemTitleScoreIcon,
  HumanRiskBehaviorsDropdownButton,
} from "./styles"

import { useRequest } from "ahooks"

import { truncateToOneDecimalPlace } from "@src/utils/numbers/utils"

import { Tooltip, Icon } from "@elevate_security/elevate-component-library"
import { Button } from "@src/components/MimecastReskin"

import { getIconAndColorByScoreDiff } from "@src/utils/colors"

import { getActionName } from "../../../../components/ActionChart/utils"

import { getActionsData } from "@src/services/apis/reputation"
import { arraySelector, useActionDataFilter } from "@src/utils/dataFilter"
import { TIME_PERIOD_MONTHS } from "../../constants"
import { isEmpty } from "@src/utils/string"
import { useSelector } from "react-redux"
import { tilesData } from "@src/scenes/IndividualRiskAnalysis/components/SideDrawer/utils"
import { SkeletonLoader } from "@src/utils/skeleton"
import { EventBadge } from "./EventBadge"
import { HumanRiskBehaviorsItemDetailsGridItemMap } from "./HumanRiskBehaviorsItemDetailsGridItem"
import moment from "moment"
import { ScoreBadge, WordScoreBadge } from "@src/components/ScoreBadges"

const colors = ["#2E7D32", "#60AD5E", "#FFB400", "#EF6C00", "#C62828"]

const formatAction = (title) => {
  return title.replace("attack_", "").replace("rwp", "real_world_phishing")
}

export const getTrendIconTooltip = (icon, trendValue) => {
  switch (icon) {
    case "TrendNeutral":
      return (
        <span>
          Risk score <strong>did not change</strong> vs. past month
        </span>
      )
    case "TrendUp":
      return (
        <span>
          Risk score got <strong>weaker by {trendValue}</strong> vs. past month
        </span>
      )
    case "TrendDown":
      return (
        <span>
          Risk score got <strong>stronger by {trendValue}</strong> vs. past
          month
        </span>
      )
    default:
      break
  }
}

const GridItem = ({
  id,
  current,
  prev,
  onOpenSideDrawer,
  person_nid,
  startDate,
  scoreType,
  onClickOverride,
  eventCountOverride,
}) => {
  const [isCollapsed, setColl] = React.useState(true)
  const currentScore = truncateToOneDecimalPlace(current) || 0
  const previousScore = truncateToOneDecimalPlace(prev) || 0

  const currentScoreDecimated = truncateToOneDecimalPlace(currentScore)

  const trendValue = (currentScore - previousScore).toFixed(1)

  const { icon, color } = getIconAndColorByScoreDiff(trendValue)

  const allowDropdown = !person_nid

  const reviewOnClick = () => {
    onOpenSideDrawer({
      keyFactor: id,
      currentFactorScore: currentScoreDecimated,
      trendValue,
    })
  }

  const barColor = arraySelector(currentScoreDecimated, colors)

  return (
    <HumanRiskBehaviorsItem
      key={id}
      data-analytics={`human-risk-behavior-${id}`}
    >
      <HumanRiskBehaviorsLeftBar style={{ backgroundColor: barColor }} />
      <HumanRiskBehaviorsItemBody>
        <HumanRiskBehaviorsItemTitle
          useHandCursor={allowDropdown || onClickOverride}
          onClick={() => {
            if (allowDropdown) {
              setColl(!isCollapsed)
            } else if (onClickOverride) {
              onClickOverride(id)
            }
          }}
        >
          <HumanRiskBehaviorsItemTitleLeft>
            {scoreType === "number" ? (
              <ScoreBadge
                text={currentScoreDecimated}
                shape="largeboldround"
                score={currentScore}
              />
            ) : (
              <WordScoreBadge shape="smallboldround" score={currentScore} />
            )}
            <HumanRiskBehaviorsItemTitleText>
              {getActionName(formatAction(id))}
            </HumanRiskBehaviorsItemTitleText>
            <HumanRiskBehaviorsItemTitleScoreIcon>
              <Tooltip
                body={getTrendIconTooltip(icon, trendValue)}
                placement="top"
                readOnly
                size="xsm"
              >
                <Icon name={icon} fill={color} />
              </Tooltip>
            </HumanRiskBehaviorsItemTitleScoreIcon>
          </HumanRiskBehaviorsItemTitleLeft>
          <HumanRiskBehaviorsItemTitleRight>
            <EventBadge
              id={id}
              person_nid={person_nid}
              startDate={startDate}
              eventCountOverride={eventCountOverride}
            />
            {allowDropdown && (
              <HumanRiskBehaviorsDropdownButton
                style={{
                  transform: `rotate(${!isCollapsed ? 0 : -180}deg)`,
                }}
              >
                <Icon name="CaretUp" size="xsm" />
              </HumanRiskBehaviorsDropdownButton>
            )}
          </HumanRiskBehaviorsItemTitleRight>
        </HumanRiskBehaviorsItemTitle>
        {!isCollapsed && (
          <HumanRiskBehaviorsItemDetails>
            <DetailsGrid
              passOnClick={
                onClickOverride ? () => onClickOverride(id) : reviewOnClick
              }
              id={id}
              person_nid={person_nid}
            />
            <HumanRiskBehaviorsItemDetailsRight>
              <Button
                onClick={
                  onClickOverride ? () => onClickOverride(id) : reviewOnClick
                }
              >
                View Details
              </Button>
            </HumanRiskBehaviorsItemDetailsRight>
          </HumanRiskBehaviorsItemDetails>
        )}
      </HumanRiskBehaviorsItemBody>
    </HumanRiskBehaviorsItem>
  )
}

export const HumanRiskBehaviorsSection = ({
  actionFactorScoreData,
  onOpenSideDrawer,
  person_nid,
  timePeriodValue,
  scoreType = "number",
  isAttackFactor = false,
  onClickOverride,
  eventTotalsByBehavior = {},
}) => {
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const startDate = timePeriodValue
    ? `${moment()
        .subtract(TIME_PERIOD_MONTHS[timePeriodValue], "month")
        .format("YYYY-MM")}-01`
    : `${moment()
        .subtract(TIME_PERIOD_MONTHS[reputations.time_period_value], "month")
        .format("YYYY-MM")}-01`

  const actionFactorData = useActionDataFilter(actionFactorScoreData)
    .slice()
    .sort((a, b) => b.current - a.current)

  const attackFactorData = useActionDataFilter(actionFactorScoreData, {
    predicate: (dashedKey, activeActions) => {
      const normalizedKey = dashedKey.replaceAll("attack-", "")
      return (
        dashedKey.startsWith("attack-") &&
        activeActions.includes(
          { rwp: "real-world-phishing" }[normalizedKey] || normalizedKey,
        )
      )
    },
  })
    .slice()
    .sort((a, b) => b.current - a.current)

  return (
    <HumanRiskBehaviorsBox>
      <ItemList
        tileData={isAttackFactor ? attackFactorData : actionFactorData}
        onOpenSideDrawer={onOpenSideDrawer}
        person_nid={person_nid}
        startDate={startDate}
        scoreType={scoreType}
        onClickOverride={onClickOverride}
        eventTotalsByBehavior={eventTotalsByBehavior}
      />
    </HumanRiskBehaviorsBox>
  )
}

function ItemList({
  tileData,
  onOpenSideDrawer,
  person_nid,
  startDate,
  scoreType,
  onClickOverride,
  eventTotalsByBehavior,
}) {
  const gridItems = tileData.map(({ key, current, prev }) => {
    return (
      <GridItem
        key={key}
        id={key}
        current={current}
        prev={prev}
        onOpenSideDrawer={onOpenSideDrawer}
        person_nid={person_nid}
        startDate={startDate}
        scoreType={scoreType}
        onClickOverride={onClickOverride}
        eventCountOverride={
          eventTotalsByBehavior[
            { attack_rwp: "actual_phishing", attack_malware: "malware" }[key] ||
              key
          ]
        }
      />
    )
  })

  return <HumanRiskBehaviorsItems> {gridItems} </HumanRiskBehaviorsItems>
}

function DetailsGrid({ passOnClick, id, person_nid }) {
  const reputations = useSelector((state) => state.get("reputationsReducer"))

  const getSpecificAction = (arr, str) => {
    return arr.findIndex((action) => action.action === str)
  }

  const { loading: insightLoader, data: insightConditions = [] } = useRequest(
    () =>
      getActionsData(
        formatAction(id?.replaceAll("-", "_")),
        TIME_PERIOD_MONTHS[reputations.time_period_value],
      ),
    {
      ready: !isEmpty(id),
      refreshDeps: [id, reputations.time_period_value],
      formatResult: (res) => {
        return (
          res?.data?.details ||
          tilesData[getSpecificAction(tilesData, id)]?.details ||
          []
        )
      },
    },
  )

  let filteredInsightConditions = []

  // Right now we are only loading the insights for the org, not the person, so
  // if used on IRP, we don't show the insights for now. We'll have to follow up
  // to add them for IRP.
  if (!person_nid) {
    if (id === "attack_rwp") {
      filteredInsightConditions = insightConditions.filter(
        ({ title }) =>
          title === "Phishing Emails Delivered" ||
          title === "Phishing Emails Blocked",
      )
    } else {
      filteredInsightConditions = insightConditions
    }
  }

  const detailsGridItems = () => {
    if (!insightLoader) {
      const humanRiskBehaviorsItemDetailsGridItems =
        filteredInsightConditions?.map((data, index) => {
          return (
            <HumanRiskBehaviorsItemDetailsGridItemMap
              passOnClick={passOnClick}
              data={data}
              key={index}
            />
          )
        })

      return (
        <HumanRiskBehaviorsItemDetailsLeft>
          {humanRiskBehaviorsItemDetailsGridItems}
        </HumanRiskBehaviorsItemDetailsLeft>
      )
    }
    return (
      <div style={{ width: "100%" }}>
        <SkeletonLoader height={90} marginTop={10} />
      </div>
    )
  }
  return detailsGridItems()
}

GridItem.propTypes = {
  id: PropTypes.string.isRequired,
  person_nid: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  scoreType: PropTypes.string.isRequired,
  current: PropTypes.number.isRequired,
  prev: PropTypes.number.isRequired,
}

HumanRiskBehaviorsSection.propTypes = {
  actionFactorScoreData: PropTypes.array.isRequired,
  person_nid: PropTypes.string,
  timePeriodValue: PropTypes.string.isRequired,
  scoreType: PropTypes.string.isRequired,
  isAttackFactor: PropTypes.bool,
}

ItemList.propTypes = {
  tileData: PropTypes.array.isRequired,
  person_nid: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  scoreType: PropTypes.string.isRequired,
}

DetailsGrid.propTypes = {
  passOnClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}
