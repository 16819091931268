import React, { useEffect, memo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import { IndividualRiskAnalysisWrapper } from "./styles"

import { theme, Typography } from "@elevate_security/elevate-component-library"

import {
  closeSideDrawer,
  setAdvancedFilters,
} from "@src/services/redux/analysisIndividuals/actions"
import { useIndividualRiskAnalysisState } from "@src/scenes/SecEngIndividualRiskAnalysis/context/IndividualRiskAnalysisContextProvider"
import { IndividualRiskAnalysisTable } from "../SecEngIndividualRiskAnalysis/components/IndividualRiskAnalysisTable"
import { useParams } from "react-router-dom"
import { createGlobalStyle } from "styled-components"
import { darklyGetFlag } from "@src/utils/darkly"
import { getOfferingPlan } from "@src/services/redux/company/selectors"
import { RiskDetectionRules } from "../SecEngIndividualRiskAnalysis/components/RiskDetectionRules"
import { SpaceBetween } from "@src/components/SpaceBetween"
import { getGlobalConfig } from "@src/globalConfig"

const { H1 } = Typography

const VisionRootOverride = createGlobalStyle`
  #vision-dashboard, #vision-dashboard-cg, #vision-dashboard-ci {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
  }
`
function IndividualRiskAnalysis({ history }) {
  const { riskType } = useParams()

  const dispatch = useDispatch()
  const tableAnalysis = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )

  useEffect(() => {
    if (tableAnalysis?.showSideBar) dispatch(closeSideDrawer())
    if (tableAnalysis?.advanced_filters) {
      dispatch(setAdvancedFilters(null))
    }
  }, [])

  const hideRiskDetectionRule = getOfferingPlan() === "assess"

  const isIndividualRiskType = riskType === "individual"

  const showRiskButton =
    (darklyGetFlag("vision-items-that-is-accessible-by-the-customer")?.includes(
      "Risk Detection Rules",
    ) &&
      isIndividualRiskType) ||
    false

  const [risksFilters, setRisksFilters] = useState([])

  const { advanced_filters } = useSelector((state) =>
    state.get("analysisIndividualReducer"),
  )

  const { userFacingBehaviorTypes } = useIndividualRiskAnalysisState()

  return (
    <>
      <VisionRootOverride />
      <IndividualRiskAnalysisWrapper>
        <SpaceBetween direction="row">
          <H1
            fontWeight="heavy"
            style={{ color: theme.colors.gray.opaque[900], margin: "0" }}
          >
            Risk Analysis
          </H1>
          {getGlobalConfig("ENABLE_R3_CREATE") &&
            !hideRiskDetectionRule &&
            showRiskButton && (
              <RiskDetectionRules
                filters={risksFilters}
                advancedFilter={structuredClone(advanced_filters)}
                enabledBehaviorTypes={userFacingBehaviorTypes || []}
              />
            )}
        </SpaceBetween>
        <IndividualRiskAnalysisTable
          history={history}
          riskType={riskType}
          onSimpleFilterChange={setRisksFilters}
        />
      </IndividualRiskAnalysisWrapper>
    </>
  )
}

IndividualRiskAnalysis.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
}

export default memo(IndividualRiskAnalysis)
