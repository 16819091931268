import React, { useState, useEffect, useRef } from "react"
import { ComboBox } from "@src/components/MimecastReskin"

import { ComboBoxWrapper, SelectOptionsWrapper } from "../styles"
import { useRequest } from "ahooks"
import {
  getQueryStringOptions,
  getGroupsOptions,
} from "@src/services/apis/policies"
import { isEmpty } from "@src/utils/string"
import Checkbox from "@src/components/Checkbox"

const LIMIT = 25

const SelectOption = ({ innerProps, getValue, label, value }) => {
  const found = getValue()?.find((val) => val.value === value)
  return (
    <SelectOptionsWrapper {...innerProps}>
      <Checkbox
        label={label}
        checked={found !== undefined}
        onChange={() => null}
        id={innerProps?.id}
      />
    </SelectOptionsWrapper>
  )
}

export const StringInputCombobox = ({
  id,
  index,
  rule,
  inputOrder,
  compoundConditionIndex,
  onHandleFieldChange,
  fieldsNamesMap,
}) => {
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(null)
  const comboBoxRef = useRef(null)

  // Using this flag to avoid unnecessary re-assignments to initValue from useEffect
  const mountedRef = useRef(false)

  const hasCsvSupport = rule.operator === "in" || rule.operator === "not_in"
  const isDynamicRule = rule.category === "groups"
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [initValue, setInitValue] = useState(hasCsvSupport ? [] : {})

  /*
    Custom mapping for api calls for custom categories other than simple string
  */

  const categoriesOptionsMapping = {
    groups: getGroupsOptions,
  }

  const queryOptionsFunction =
    categoriesOptionsMapping[rule?.category] ?? getQueryStringOptions

  /*
  We have an ability in query builder for string input combobox component
  so if values does not exists in dropdown it should add value as a plain string,
  but this should remain disable for other categories like groups, applications
  */

  const isCustomValueAllowed = !categoriesOptionsMapping[rule?.category]

  const { data, loading } = useRequest(
    () =>
      queryOptionsFunction({
        category: rule?.category,
        field: rule?.field,
        search: `${search ?? ""}`,
        page_size: LIMIT,
        page_number: page,
      }),
    {
      debounceInterval: 500,
      refreshDeps: [search, page],
      formatResult: (response) => {
        const resData = response?.data?.results || []
        //adding search item at first index in the response list
        if (search && isCustomValueAllowed) {
          resData.unshift({ label: `Add "${search}"`, value: search })
        }

        if (page === 0) {
          return resData
        }
        return [...data, ...resData]
      },
    },
  )

  const handleSetSearch = (value) => {
    if (search === value) return
    setSearch(value)
    setPage(0)
  }

  const handleInputSearch = (option, { action }) => {
    if (action === "input-change") {
      if (search === option) return
      setInitValue({ label: option, value: option })
      setSearch(option)
      setPage(0)
    }
  }

  const handleOutsideClick = (event) => {
    if (
      menuIsOpen &&
      comboBoxRef.current &&
      !comboBoxRef.current.contains(event.target)
    ) {
      setMenuIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [menuIsOpen])

  useEffect(() => {
    if (rule?.operator) {
      setInitValue(null)
    }
  }, [rule?.operator])

  useEffect(() => {
    let iniValue = hasCsvSupport ? [{ label: "", value: "" }] : null
    if (!data?.length || mountedRef?.current === true) {
      return
    }

    if (hasCsvSupport && !isEmpty(rule.value?.[inputOrder])) {
      const ids = rule.value?.[inputOrder]?.split(",")
      const targets = data?.filter((item) => ids.includes(item.value))
      if (isDynamicRule && !targets?.length) {
        iniValue = ids?.map((id) => ({
          label: fieldsNamesMap?.[id],
          value: id,
        }))
      } else if (!isDynamicRule && !targets?.length) {
        iniValue = ids?.map((id) => ({ label: id, value: id }))
      } else {
        iniValue = targets
      }
    } else if (hasCsvSupport && isEmpty(rule.value?.[inputOrder])) {
      iniValue = []
    } else if (!hasCsvSupport && !isEmpty(rule.value?.[inputOrder])) {
      iniValue =
        data?.find((item) => item.value === rule.value?.[inputOrder]) ?? null

      if (isDynamicRule && isEmpty(iniValue)) {
        iniValue = {
          label: fieldsNamesMap?.[rule.value?.[inputOrder]],
          value: rule.value?.[inputOrder],
        }
      } else if (!isDynamicRule && isEmpty(iniValue)) {
        iniValue = {
          label: rule.value?.[inputOrder],
          value: rule.value?.[inputOrder],
        }
      }
    }

    setInitValue(iniValue)
    // This will make sure the above re-assignment does not run unnecessary
    mountedRef.current = true
  }, [data, fieldsNamesMap])

  return (
    <ComboBoxWrapper ref={comboBoxRef}>
      <ComboBox
        data={data}
        defaultValue={hasCsvSupport ? [] : {}}
        value={initValue}
        {...(!hasCsvSupport && { inputValue: initValue?.label })}
        onChange={(selected) => {
          const value = [...rule.value]
          value[inputOrder] = hasCsvSupport
            ? selected?.map((i) => i.value)?.join(",")
            : selected?.value
          if (hasCsvSupport) {
            setInitValue([...selected])
          } else {
            setInitValue({ label: selected.label, value: value[0] })
          }
          mountedRef.current = !isDynamicRule // This will make sure initValye re-assignment triggers to map value with label for selected group
          if (typeof compoundConditionIndex === "number") {
            onHandleFieldChange(id, index, compoundConditionIndex, {
              name: "value",
              value,
            })
          } else {
            onHandleFieldChange(id, index, {
              name: "value",
              value,
            })
          }
          if (!hasCsvSupport) {
            setMenuIsOpen(false)
          }
        }}
        isLoading={loading}
        clearable={false}
        isSearchable={true}
        isMultiOption={hasCsvSupport}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={hasCsvSupport ? { Option: SelectOption } : {}}
        placeholder="Select values"
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        handleScrollBotttom={() => {
          if (data.length == LIMIT) {
            setPage(page + 1)
          }
        }}
        {...(hasCsvSupport
          ? { handleInputChange: handleSetSearch }
          : { onInputChange: handleInputSearch })}
      />
    </ComboBoxWrapper>
  )
}
