import React, { useState } from "react"

import { MimecastCgBreadcrumbs } from "@src/MimecastCgBreadcrumbs"
import { TableWithFiltersScreen } from "../TableWithFiltersScreen"
import { Link, useParams } from "react-router-dom"
import ActivityIndicator from "@src/components/ActivityIndicator"
import { useWatchlistIndividuals as _useWatchlistIndividuals } from "./useWatchlistIndividuals"
import { useWatchlist as _useWatchlist } from "./useWatchlist"

export function WatchlistIndividualsScreen() {
  const { watchlistId } = useParams<{ watchlistId: string }>()
  return <WatchlistIndividualsScreenContent watchlistId={watchlistId} />
}

export function WatchlistIndividualsScreenContent({
  useWatchlist = _useWatchlist,
  useWatchlistIndividuals = _useWatchlistIndividuals,
  watchlistId,
}: {
  useWatchlist?: typeof _useWatchlist
  useWatchlistIndividuals?: typeof _useWatchlistIndividuals
  watchlistId: string
}) {
  const [pagination, setPagination] = useState({ pageSize: 25, current: 1 })

  const {
    watchlist,
    loading: loadingWatchlist,
    error: watchlistError,
  } = useWatchlist({ watchlistId })

  const {
    individuals,
    loading: loadingIndividuals,
    error: individualsError,
  } = useWatchlistIndividuals({
    watchlistId,
    pagination,
  })

  const columns = [
    {
      key: "name",
      header: "NAME",
      render: (
        _: undefined,
        {
          first_name,
          last_name,
          mc_person_id,
        }: {
          first_name: string
          last_name: string
          mc_person_id: string
        },
      ) => {
        return (
          <Link to={`/engagement/vision2/${mc_person_id}/profile`}>
            {first_name} {last_name}
          </Link>
        )
      },
    },
    {
      key: "email",
      header: "EMAIL",
    },
  ]

  return (
    <div>
      {(loadingWatchlist || loadingIndividuals) && <ActivityIndicator active />}
      <MimecastCgBreadcrumbs
        breadcrumbs={[
          "risk_response_engine",
          "watchlists",
          {
            label: "People",
            url: `/admin/engagement/vision2/risk-response-engine/watchlists/${watchlistId}/individuals`,
          },
        ]}
      />
      <TableWithFiltersScreen
        title={`Watchlist: ${watchlist?.name ? `"${watchlist?.name}"` : "Loading..."}`}
        subtitle={`Total people: ${individuals?.total_elements ?? "Loading..."}`}
        appliedFiltersCount={0}
        searchValue=""
        onSearch={() => {}}
        clearSearch={() => {}}
        entries={individuals?.results ?? []}
        totalCount={individuals?.total_elements ?? 0}
        loading={!watchlist || !individuals}
        pagination={{
          ...pagination,
          onChange: (current: number, pageSize: number) => {
            setPagination({ current, pageSize })
          },
        }}
        columns={columns}
        onSortedChange={() => {}}
        showSearch={false}
        excludeSortColumn={columns.map((column) => column.key)}
        emptyMessage="No people match the watchlist"
        errorMessage={
          (watchlistError &&
            `Failed to load watchlist: ${watchlistError.message}`) ||
          (individualsError &&
            `Failed to load individuals: ${individualsError.message}`)
        }
      >
        {null}
      </TableWithFiltersScreen>
    </div>
  )
}
