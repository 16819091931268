import React from "react"
import { getGlobalConfig } from "@src/globalConfig"
import { useSelector } from "react-redux"
import { selectActiveActions } from "@src/services/redux/reputations/selectors"
import { getActionName } from "../ActionChart/utils"
import { sortStringsSafe } from "@src/utils/string"

export const HumanRiskDashboardHumanRiskScoreInfoTooltip = () => {
  return (
    <div>
      The Human Risk Score is based on the things users do, both good and bad
      &mdash; their behavior &mdash; that contribute to risk. The lower the
      score, the lower the calculated risk. A low score is good, and the basis
      of Human Risk Score is {getGlobalConfig("BEHAVIOR_DESCRIPTOR")}.
      <br />
      <br />
      The Human Risk Score considers behaviors in the following areas:{" "}
      <EnabledBehaviorList />. Examples of actions that contribute to{" "}
      {getGlobalConfig("BEHAVIOR_DESCRIPTOR")} include reporting a simulated
      phishing email, clicking on a link in a phishing email, and completing
      training.
    </div>
  )
}

export const IndividualRiskProfileHumanRiskScoreInfoTooltip = () => {
  // IRP description of Human Risk Score is the same as main dashboard, for
  // the time being
  return <HumanRiskDashboardHumanRiskScoreInfoTooltip />
}

export const HumanRiskDashboardAttackFactorInfoTooltip = () => {
  return (
    <div>
      Measures how frequently your users are targeted by outside sources.
      <br />
      <br />
      These factors are not something you can control, and scores are directly
      influenced by time. Tenure also comes into play, and a longer tenured
      employee&apos;s score is lower.
      <br />
      <br />A low score is good. Attack Factor includes:{" "}
      <EnabledAttackFactorBehaviorList />.
    </div>
  )
}

export const IndividualRiskProfileAttackFactorInfoTooltip = () => {
  return (
    <div>
      Measures how frequently you are targeted compared to others in your
      organization.
      <br />
      <br />
      These factors are not something you can control, and your score is
      directly influenced by time. Tenure also comes into play, and a longer
      tenured employee&apos;s score is lower.
      <br />
      <br />A low score is good. Attack Factor includes:{" "}
      <EnabledAttackFactorBehaviorList />.
    </div>
  )
}

type BehaviorFilter = (behavior: string) => boolean

const useEnabledBehaviorsList = (filter: BehaviorFilter = () => true) => {
  const activeActions = (useSelector(selectActiveActions) || []).filter(
    (action: string) => action !== "overall",
  )
  return sortStringsSafe(activeActions.filter(filter).map(getActionName)).join(
    ", ",
  )
}

const EnabledBehaviorList = () => {
  const enabledBehaviorsList = useEnabledBehaviorsList()
  return <span>{enabledBehaviorsList}</span>
}

const EnabledAttackFactorBehaviorList = () => {
  const enabledAttackFactorBehaviorsList = useEnabledBehaviorsList((behavior) =>
    ["malware", "real-world-phishing"].includes(behavior),
  )
  return <span>{enabledAttackFactorBehaviorsList}</span>
}
